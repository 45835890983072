<template>
  <div class="success">
    <div class="success-title">
      <el-divider direction="vertical" class="line"></el-divider>
      <span>收银台</span>
    </div>
    <div class="success-icon el-icon-circle-check"></div>
    <div class="success-type">支付成功</div>
    <div class="success-btn" @click="back">返回控制台</div>
  </div>
</template>

<script>
export default {
  methods: {
    back() {
      this.$router.push('/control');
    }
  }
};
</script>

<style lang="scss" scoped>
.success {
  background-color: #fff;
  padding: 20px;
  &-title {
    text-align: left;
    font-size: 25px;
    font-weight: 600;
    line-height: 30px;
    .line {
      width: 3px;
      height: 28px;
    }
  }
  &-icon {
    font-size: 100px;
    color: green;
    margin-top: 60px;
  }
  &-type {
    margin-top: 20px;
    font-size: 25px;
    font-weight: 500;
  }
  &-btn {
    width: 200px;
    height: 50px;
    margin: 30px auto;
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 50px;
    text-align: center;
    background-color: #67c23a;
    border-radius: 2px;
    cursor: pointer;
  }
}
</style>